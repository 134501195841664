import React from "react";

const WhyOrange = () => {
  return (
    <div className="text-dark text-center">
      <h2>Why FindXStorage?</h2>
      <p>
        FindxStorage makes it easy for both storage unit operators to easily
        list available storage units and prospective renters to find available
        storage units. FindXStorage works with storage unit operators around the
        world so finding a storage unit is easy and convenient.
      </p>
      <div className="row mt-5">
        <div className="col-md-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65.494"
            height="80.199"
            viewBox="0 0 65.494 80.199"
            aria-labelledby="titleSecurity"
            role="img"
          >
            <title id="titleSecurity">Security Icon</title>
            <path
              id="Path_21"
              data-name="Path 21"
              d="M72.383,35.466h-8.1V22.254a22.215,22.215,0,1,0-44.431,0h0V35.466H11.9a2.514,2.514,0,0,0-2.5,2.509V75.8a2.514,2.514,0,0,0,2.5,2.509h6.168l.889,1.893H23.68l.889-1.893H29.28l.889,1.893h4.725l.889-1.893H48.507L49.4,80.2h4.725l.889-1.893h4.71l.889,1.893h4.725l.889-1.893h6.158a2.514,2.514,0,0,0,2.5-2.509V37.975A2.514,2.514,0,0,0,72.383,35.466ZM42.069,8.523A13.719,13.719,0,0,1,55.776,22.254V35.466H28.362V22.252h0A13.719,13.719,0,0,1,42.069,8.523ZM69.61,70.259v2.31H42.446V71.412H14.651v-2.3H69.61Zm0-6.976v2.31H42.446V64.436H14.651v-2.3H69.61Zm0-6.976v2.31H42.446V57.46H14.651v-2.3H69.61Zm0-6.976v2.31H42.446V50.484H14.651v-2.3H69.61Zm0-6.976v2.309H42.446V43.508H14.651V41.2H69.61Z"
              transform="translate(-9.393)"
              fill="black"
            ></path>
          </svg>
          <h6 className="mt-3">State of Art Security System</h6>
        </div>
        <div className="col-md-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80.172"
            height="80.172"
            viewBox="0 0 80.172 80.172"
            aria-labelledby="titleNoFees"
            role="img"
          >
            <title id="titleNoFees">No Fees Icon</title>
            <g id="Group_795" data-name="Group 795">
              <path
                id="Path_230"
                data-name="Path 230"
                d="M39.983,0A40.086,40.086,0,1,0,80.172,40.086,40.079,40.079,0,0,0,39.983,0m0,71.048a30.778,30.778,0,0,1-24.6-49.09l6.5,6.494L37.939,44.478l8.6,8.586,7.06,7.05,4.825,4.818a30.742,30.742,0,0,1-18.437,6.116M64.8,58.521l-5.565-5.557-19-18.973-6.156-6.148-7.494-7.484-4.815-4.808A30.383,30.383,0,0,1,39.983,9.513,30.736,30.736,0,0,1,64.8,58.521m-19.98-3.736,6.534,6.524a25.317,25.317,0,0,1-8.684,1.962v5.221q-1.334.124-2.691.125c-.872,0-1.731-.04-2.583-.12V63.271c-8.24-.724-17.337-4.943-17.337-14.371H33.114c0,4.878,3.034,6.855,7.451,6.855a8.309,8.309,0,0,0,4.259-.97M21.649,31.64,33.888,43.864c-6.118-1.1-12.063-3.412-12.239-12.224m18.52-7.789a6.585,6.585,0,0,0-4.9,1.744l-6.652-6.644A20.694,20.694,0,0,1,37.4,16.4V12.061q1.282-.116,2.583-.118c.907,0,1.806.043,2.691.128v4.264c10.678.659,15.887,6.066,15.887,12.92H46.167c-.2-3.56-2.505-5.4-6-5.4M59.944,48.306a16.163,16.163,0,0,1-.1,1.826L44.293,34.6c7.256,1.057,15.651,2.736,15.651,13.7"
                fill="black"
              ></path>
            </g>
          </svg>
          <h6 className="mt-3">State of Art Security System</h6>
        </div>
        <div className="col-md-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Group_810"
            width="75.184"
            height="80"
            viewBox="0 0 75.184 80"
            aria-labelledby="titleAccount"
            role="img"
          >
            <title id="titleAccount">Account Icon</title>
            <path
              id="Path_209"
              data-name="Path 209"
              d="M47.421,34.255A17.128,17.128,0,1,0,30.293,17.128,17.128,17.128,0,0,0,47.421,34.255"
              transform="translate(-10.012)"
              fill="black"
            ></path>
            <path
              id="Path_210"
              data-name="Path 210"
              d="M14.428,99.416l0-17.157,3.866.013V99.416H56.889V82.272l3.866-.013,0,17.157H75.175l.009-24.836c0-14.457-10.506-15.838-22.441-15.835h-30.3C10.506,58.742,0,60.123,0,74.58L.009,99.416Z"
              transform="translate(0 -19.416)"
              fill="black"
            ></path>
          </svg>
          <h6 className="mt-3">State of Art Security System</h6>
        </div>
        <div className="col-md-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="78.305"
            height="80"
            viewBox="0 0 78.305 80"
            aria-labelledby="titleFreeStorage"
            role="img"
          >
            <title id="titleFreeStorage">Free Storage Icon</title>
            <g id="Group_784" data-name="Group 784" transform="translate(0 0)">
              <path
                id="Path_241"
                data-name="Path 241"
                d="M0,0H6.595V3.3H0Z"
                transform="translate(19.165 75.351) rotate(-9.999)"
                fill="black"
              ></path>
              <path
                id="Path_177"
                data-name="Path 177"
                d="M107.316,294.356l.569,3.247,6.495-1.146-.573-3.243Z"
                transform="translate(-80.68 -220.439)"
                fill="black"
              ></path>
              <path
                id="Path_178"
                data-name="Path 178"
                d="M143.9,287.841l-6.495,1.146.573,3.247,6.495-1.145-.573-3.268Z"
                transform="translate(-103.3 -216.499)"
                fill="black"
              ></path>
              <path
                id="Path_240"
                data-name="Path 240"
                d="M0,0H6.595V3.3H0Z"
                transform="translate(11.694 76.753) rotate(-10.005)"
                fill="black"
              ></path>
              <path
                id="Path_179"
                data-name="Path 179"
                d="M15.874,224.256l-3.247.569,1.146,6.495,3.247-.573Z"
                transform="translate(-9.222 -168.586)"
                fill="black"
              ></path>
              <path
                id="Path_180"
                data-name="Path 180"
                d="M10.511,194.167l.016-.012-3.264.585,1.146,6.495,3.247-.573Z"
                transform="translate(-5.305 -145.958)"
                fill="black"
              ></path>
              <path
                id="Path_181"
                data-name="Path 181"
                d="M27.389,290.048a1.271,1.271,0,0,1-.2-.432l-.867-4.928-.065-.245-3.01.531.867,4.928a4.349,4.349,0,0,0,2.032,2.968l1.585-2.617a1.234,1.234,0,0,1-.26-.206Z"
                transform="translate(-17.436 -213.333)"
                fill="black"
              ></path>
              <path
                id="Path_182"
                data-name="Path 182"
                d="M20.967,254.354l0-.011-3,.542,1.058,6.02,3.01-.531Z"
                transform="translate(-13.47 -190.757)"
                fill="black"
              ></path>
              <path
                id="Path_183"
                data-name="Path 183"
                d="M251.742,271.914l0,.016h0Z"
                transform="translate(-188.807 -203.936)"
                fill="black"
              ></path>
              <path
                id="Path_184"
                data-name="Path 184"
                d="M5.525,166.853v-.034c-.248-1.413-.481-2.75-.481-2.75l-.011-.016-3.01.531,1.062,6.02,3.007-.531Z"
                transform="translate(-1.517 -123.04)"
                fill="black"
              ></path>
              <path
                id="Path_185"
                data-name="Path 185"
                d="M311.686,248.719l0,.01.013,0Z"
                transform="translate(-233.764 -186.54)"
                fill="black"
              ></path>
              <path
                id="Path_186"
                data-name="Path 186"
                d="M263.856,266.627l-6,1.047.531,3.01,6-1.062-.531-3.01Z"
                transform="translate(-193.391 -199.959)"
                fill="black"
              ></path>
              <path
                id="Path_187"
                data-name="Path 187"
                d="M293.678,250.537l-.315-1.778-3,.525.027.149.042.245a1.438,1.438,0,0,0,.042.233v.592l.019.115v.133l.031.172V251a1.3,1.3,0,0,1-1.046,1.5H289.4l-2.151.382.672,3.079,2.219-.382a4.359,4.359,0,0,0,3.534-5.046"
                transform="translate(-215.44 -186.569)"
                fill="black"
              ></path>
              <path
                id="Path_188"
                data-name="Path 188"
                d="M191.555,282.534l0,.016h0Z"
                transform="translate(-143.666 -211.9)"
                fill="black"
              ></path>
              <path
                id="Path_189"
                data-name="Path 189"
                d="M167.5,283.657l.531,3.01,6.013-1.077-.528-2.995Z"
                transform="translate(-125.629 -211.947)"
                fill="black"
              ></path>
              <path
                id="Path_190"
                data-name="Path 190"
                d="M227.692,273.04l.531,3.01,6.02-1.062-.536-3.009Z"
                transform="translate(-170.769 -203.984)"
                fill="black"
              ></path>
              <path
                id="Path_191"
                data-name="Path 191"
                d="M203.611,277.293l-6.02,1.062.531,3.006,6.02-1.054-.531-3.01Z"
                transform="translate(-148.193 -207.969)"
                fill="black"
              ></path>
              <path
                id="Path_192"
                data-name="Path 192"
                d="M98.741,118.046v.019l-6.016,1.043.531,3.01,6.016-1.062Z"
                transform="translate(-69.544 -88.534)"
                fill="black"
              ></path>
              <path
                id="Path_193"
                data-name="Path 193"
                d="M29.939,132.648l5.288,29.986a1.3,1.3,0,0,0,1.5,1.054l55.47-9.781a1.3,1.3,0,0,0,1.054-1.5l-5.287-29.987a1.3,1.3,0,0,0-1.5-1.053l-55.47,9.781a1.3,1.3,0,0,0-1.054,1.5m12.2,23.016-.515-2.919,27.191-4.795.515,2.919Zm40.837-15.406-42.239,7.448-.515-2.919L82.46,137.34Zm-1.92-10.874.515,2.919L39.33,139.752l-.515-2.919Z"
                transform="translate(-22.439 -91.007)"
                fill="black"
              ></path>
              <path
                id="Path_194"
                data-name="Path 194"
                d="M36.49,132.129h.03l2.563-.451-.531-3.01-.008,0-6.024,1.062.531,3.007Z"
                transform="translate(-24.391 -96.499)"
                fill="black"
              ></path>
              <path
                id="Path_195"
                data-name="Path 195"
                d="M278.483,128.856v.676l.932,5.283,3.01-.531-.932-5.986v0l-3.01.531Z"
                transform="translate(-208.862 -96.223)"
                fill="black"
              ></path>
              <path
                id="Path_196"
                data-name="Path 196"
                d="M68.647,123.347l-6.024,1.062.531,3,6.02-1.062Z"
                transform="translate(-46.967 -92.51)"
                fill="black"
              ></path>
              <path
                id="Path_239"
                data-name="Path 239"
                d="M0,0H3.057V6.113H0Z"
                transform="translate(73.601 55.193) rotate(-10.005)"
                fill="black"
              ></path>
              <path
                id="Path_197"
                data-name="Path 197"
                d="M146.8,112.789l0,.011.021,0Z"
                transform="translate(-110.1 -84.592)"
                fill="black"
              ></path>
              <path
                id="Path_198"
                data-name="Path 198"
                d="M284.892,164.947l3.01-.531L286.84,158.4l-.012,0-3,.527Z"
                transform="translate(-212.873 -118.8)"
                fill="black"
              ></path>
              <path
                id="Path_199"
                data-name="Path 199"
                d="M289.706,192.47v.019c.237,1.349.451,2.575.451,2.575l3.01-.531-1.058-6.032-3.006.531Z"
                transform="translate(-216.827 -141.376)"
                fill="black"
              ></path>
              <path
                id="Path_200"
                data-name="Path 200"
                d="M19.9,27.583a6.734,6.734,0,0,0,9.878-6.112l4.863-1.5c1.146,1.91,2.429,4.168,3.64,6.185l6.494-1.146L33.046,10.649a6.765,6.765,0,1,0-2.533,2.177c.317.584,1.051,1.91,2.013,3.572-1.723.856-3.056,1.528-3.656,1.857A6.735,6.735,0,1,0,19.9,27.583M31.54,7.2a4.042,4.042,0,0,1-4.411,3.634l-.016,0A4.042,4.042,0,1,1,31.54,7.2M19.468,19.739v0A4.042,4.042,0,1,1,21.158,25.2a4.042,4.042,0,0,1-1.688-5.462"
                transform="translate(-12.229 0)"
                fill="black"
              ></path>
              <path
                id="Path_201"
                data-name="Path 201"
                d="M106.728,45.439,121.737,40.8s-3.438-3.656-7.029-4.038c-1.65-.172-7.258,2.708-11.349,4.557l3.362,4.118Z"
                transform="translate(-77.519 -27.567)"
                fill="black"
              ></path>
              <path
                id="Path_202"
                data-name="Path 202"
                d="M183.511,106.2l6.02-1.062L189,102.129l-6.017,1.061Z"
                transform="translate(-137.238 -76.597)"
                fill="black"
              ></path>
              <path
                id="Path_203"
                data-name="Path 203"
                d="M153.443,111.586l6.016-1.058-.527-3.01-6.02,1.058Z"
                transform="translate(-114.684 -80.638)"
                fill="black"
              ></path>
              <path
                id="Path_204"
                data-name="Path 204"
                d="M3.08,139.044a1.3,1.3,0,0,1,.97-1.49h.084l3.029-.577-.535-3.01L3.6,134.5a4.355,4.355,0,0,0-3.533,5.042l.172.982,3.01-.531Z"
                transform="translate(0 -100.475)"
                fill="black"
              ></path>
              <path
                id="Path_205"
                data-name="Path 205"
                d="M207.049,102.124h0Z"
                transform="translate(-155.287 -76.593)"
                fill="black"
              ></path>
              <path
                id="Path_206"
                data-name="Path 206"
                d="M249.023,94.513s1.645-.366,2.071,2.055l.455,2.571,3.006-.531-.726-4.118c-.363-2.083-2.172-3.159-4.286-3.156l-.577.084-5.795,1.024.531,3.007Z"
                transform="translate(-182.378 -68.501)"
                fill="black"
              ></path>
              <path
                id="Path_207"
                data-name="Path 207"
                d="M123.338,116.884l3.778-.668,2.223-.382-.529-3-6,1.039Z"
                transform="translate(-92.108 -84.626)"
                fill="black"
              ></path>
              <path
                id="Path_208"
                data-name="Path 208"
                d="M217.676,100.179l1.975-.348-.531-3.01-6.02,1.062.531,3.01Z"
                transform="translate(-159.824 -72.616)"
                fill="black"
              ></path>
            </g>
          </svg>
          <h6 className="mt-3">State of Art Security System</h6>
        </div>
      </div>
      <p className="mt-5">
        Each storage facility is unique to its market, offering a wide variety
        of storage spaces and types. Features such as climate controlled
        storage, heated storage and 24-hour access vary by facility, but all
        storage location are backed by several FindxStorage advantages.
      </p>
    </div>
  );
};

export default WhyOrange;
