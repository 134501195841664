const dummyClients = [
  {
    id: 1,
    name: "John Doe",
    description:
      "As a storage unit operator, FindxStorage has revolutionized our business. Listing our available units is incredibly easy, and we’ve seen a significant increase in bookings. The platform’s user-friendly interface and extensive reach have brought us more clients than ever before. We highly recommend FindxStorage to other operators looking to grow their business.",
    profilePic: "/images/person3.jpg",
    unitName: "Steven Travellers",
    rating: 4.5,
  },
  {
    id: 2,
    name: "Kevin Swanson",
    description:
      "FindxStorage came highly recommended by a friend, and now I understand why. Booking a unit was effortless, and I snagged a fantastic deal. The whole process was smooth sailing, from reservation to moving in. The savings are real. Highly recommended!",
    profilePic: "/images/person4.jpg",
    unitName: "Brandon Travellers",
    rating: 5,
  },
  {
    id: 3,
    name: "Grace Smith",
    description:
      "FindxStorage has been a game-changer for our operations. Their team has been exceptionally supportive, helping us maximize our listings and reach a wider audience. The platform’s efficiency and customer-focused approach have enhanced our service delivery, resulting in higher customer satisfaction and increased revenue. We’re thrilled to partner with FindxStorage.",
    profilePic: "/images/person1.jpg",
    unitName: "Gohar Travellers",
    rating: 3,
  },
  {
    id: 4,
    name: "Teena Holland",
    description:
      "FindxStorage exceeded my expectations in every way. When I needed temporary storage during a home renovation, their team was there to assist me every step of the way. What impressed me the most was the exceptional customer service. The staff was responsive, attentive, and genuinely cared about my satisfaction. I wouldn't hesitate to use FindxStorage again and recommend them wholeheartedly.",
    profilePic: "/images/person2.jpg",
    unitName: "Gohar Travellers",
    rating: 3,
  },
];

export default dummyClients;
